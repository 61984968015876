'use client';
import React from 'react';
import { ThemeVarsProps } from 'statics/lib/types/variables';
import { Country } from 'ui-library/components/organisms/CountryCards/types';

type Price = {
	min: { amount: number; currency: string };
	max: { amount: number; currency: string };
};

type Prices = {
	official_price: Price;
	service_fee: Price;
	total_price: Price;
	non_refundable_amount: Price;
}[];

export type ProductType = {
	name: string;
	availableLanguages: { locale: string; name: string }[];
	emailFromName: string;
	slaMin: number;
	slaMax: number;
	governmentUrl: string;
	sitePhone: string;
	chatCode: any;
	chatClient: boolean;
	chatIpBlocked: string | null;
	freshchatCode: string;
	eligibleCountries: Country[];
};

export type Site = {
	name: string;
	infoEmail: string;
	visaEmail: string;
	emailFromName: string;
	language: string;
	productType: string;
	googleAnalytics: any;
	googleTagManager: any;
	naver: any;
	yahoojp: any;
	vwo: any;
	index: boolean;
	baidu: any;
	china360: any;
	landingTheme: string | null;
	allowedIpCountries: string[];
	url: string;
	companyCif: string;
	companyName: string;
	companyPhone: string;
	companyAddress: string;
	morName: string;
	morCif: string;
	morRegistry: any;
	morAddress: string;
};

/*
 *
 * @export
 * @interface CrmData
 * */
export interface CrmData {
	productType: ProductType;
	site: Site;
}

export interface AppContextProps {
	hostUrl: string;
	themeVars: ThemeVarsProps;
}

export const AppContext = React.createContext({} as AppContextProps);

export default AppContext;
