'use client';
import React, { useEffect, useRef, useState } from 'react';
import {
	CurrentLocale,
	LocaleItem,
	LocaleList,
	LocaleSwitcherContainer,
} from './style';
import { Body } from '../../atoms/FontScale/Body';
import { MdExpandMore, MdExpandLess } from 'react-icons/md';
import capitalize from 'ui-library/lib/capitalize';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useLocale } from 'next-intl';
import { isMobile } from 'ui-library/lib/isMobile';
import { useWindowWidth } from 'ui-library/hooks/useWindowWidth';
import { useCrmData } from 'statics/hooks/useCrmData';
import getProductType from 'statics/services/getProductType';

// NOTE: These isocodes will be shortened by region code (ex.: ja-JP -> jp) and
// at the same the collection will be used to get current locale (shortcode)
// correct displayName when selected as displaynames work better with regions.
const LOCALE_SHORTCODE_BY_REGION: Record<string, string> = {
	jp: 'ja-JP',
	tw: 'zh-TW',
	cn: 'zh-CN',
	kr: 'ko-KR',
	ua: 'uk-UA',
};

// HACK: VISAS-49352 for testing purposes
const acceptedSouthKoreaLocales = ['en', 'fr'];

/**
 * @todo
 *  - NOTE: Link 'locale' prop does not prepend correct locale on href
 *    so we build it manually.
 */
export default function LocaleSwitcher() {
	const currentLocale = useLocale();
	const pathname = usePathname();
	const windowWidth = useWindowWidth();
	const isMobileView = isMobile(windowWidth);

	const [isOpen, setIsOpen] = useState<boolean>(false);
	const localeSwitchRef = useRef<HTMLDivElement | null>(null);

	const { data: crmData } = useCrmData();
	if (!crmData) {
		throw new Error('CRM data not found');
	}

	const { product } = getProductType();
	const {
		productType: { availableLanguages },
	} = crmData;

	const getLocaleShortCode = (locale: string) => {
		const splitIndex = Object.values(
			LOCALE_SHORTCODE_BY_REGION,
		).some((shortcode) => shortcode === locale)
			? 1
			: 0;
		return locale.split('-')[splitIndex].toLocaleLowerCase() || '';
	};

	const getLocaleName = (
		locale: string,
		fromShortToLong: boolean = false,
	): string => {
		let localeCode: string;
		if (fromShortToLong) {
			localeCode = LOCALE_SHORTCODE_BY_REGION[locale] || locale;
		} else {
			localeCode = Object.values(LOCALE_SHORTCODE_BY_REGION).some(
				(shortcode) => shortcode === locale,
			)
				? locale
				: getLocaleShortCode(locale);
		}
		const localeNames = new Intl.DisplayNames([localeCode], {
			type: 'language',
		});
		return capitalize(localeNames.of(localeCode) || 'English');
	};

	const getSlugFromUrl = (): string => pathname?.split('/')[2] || '';

	const expand = () => setIsOpen(true);

	const close = () => setIsOpen(false);

	const checkIfClickedOutside = (e: { target: any }) => {
		if (
			isOpen &&
			localeSwitchRef.current &&
			!localeSwitchRef.current.contains(e.target)
		) {
			close();
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', checkIfClickedOutside);
		return () => {
			document.removeEventListener(
				'mousedown',
				checkIfClickedOutside,
			);
		};
	}, [isOpen]);

	return (
		<LocaleSwitcherContainer ref={localeSwitchRef}>
			<CurrentLocale onClick={isOpen ? close : expand}>
				<Body size="L" variant="light">
					{getLocaleName(currentLocale, true)}
				</Body>
				{isOpen ? (
					<MdExpandLess size={'24px'} />
				) : (
					<MdExpandMore size={'24px'} />
				)}
			</CurrentLocale>
			<LocaleList $isOpen={isOpen} $isMobile={isMobileView}>
				{availableLanguages
					?.filter(({ locale }) => {
						const localeShortCode =
							getLocaleShortCode(locale);
						if (currentLocale === localeShortCode)
							return false;
						// HACK: VISAS-49352 for testing purposes
						if (product === 'southkorea') {
							return acceptedSouthKoreaLocales.includes(
								localeShortCode,
							);
						} else {
							return true;
						}
					})
					.map(({ locale, name }) => {
						return (
							<LocaleItem key={locale}>
								<Link
									href={`/${getLocaleShortCode(
										locale,
									)}/${getSlugFromUrl()}`}>
									<Body size="L" variant="light">
										{name}
									</Body>
								</Link>
							</LocaleItem>
						);
					})}
			</LocaleList>
		</LocaleSwitcherContainer>
	);
}
