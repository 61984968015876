import React from 'react';
import { FilterContext } from 'statics/services/CrmDataContext';
import {
	WrapperCountryCards,
	LinkContainer,
	CountryCardsContainer,
	BlurContainer,
	NoMatchFound,
	NoMatchFoundTitle,
	NoMatchFoundBody,
} from './style';
import { CountryCardsProps } from './types';
import { Filter } from 'ui-library/components/atoms/Forms/Inputs/Filter';
import { Body } from 'ui-library/components/atoms/FontScale/Body';
import Button from 'ui-library/components/atoms/Button';
import { Link } from 'ui-library/components/atoms/Link';
import { CountryCard } from 'ui-library/components/molecules/CountryCard';
import { CountryCardSelect } from 'ui-library/components/molecules/CountryCardSelect';
import { useCountryCards } from './useCountryCards';
import { Skeleton } from 'ui-library/components/layout/Skeleton';
import { CountryCardSkeleton } from './skeleton';

export const CountryCards = ({
	cardUrl,
	collapsedLabel = 'View all',
	expandedLabel = 'View less',
	linkText = 'Apply for South Korea eTA',
	inputPlaceholder = 'Search your country…',
	previewMode = false,
	isLoading = false,
}: CountryCardsProps) => {
	const {
		dataFiltered,
		setFilterContext,
		countries,
		isNotMobile,
		isFiltered,
		hasFilterResults,
		shouldExpandFilter,
		isCollapsed,
		toggle,
	} = useCountryCards();

	if (isLoading)
		return (
			<Skeleton renderSkeleton={() => <CountryCardSkeleton />} />
		);

	const renderCountries = () =>
		dataFiltered && dataFiltered.length > 0 ? (
			dataFiltered.map(({ code, isoCode, name }) => {
				const src = `/common/flags/${code.toLowerCase()}.svg`;
				return (
					<CountryCard
						key={isoCode}
						name={name}
						src={src}
						href={`${cardUrl}?option=${code.toUpperCase()}`}
					/>
				);
			})
		) : (
			<>
				<NoMatchFound>
					<NoMatchFoundTitle>
						Country not available
					</NoMatchFoundTitle>
					<NoMatchFoundBody>
						Please check if the country you are looking for
						is spelled correctly.
					</NoMatchFoundBody>
				</NoMatchFound>
			</>
		);

	if (isNotMobile) {
		return (
			<FilterContext.Provider
				value={[dataFiltered, setFilterContext]}>
				<WrapperCountryCards>
					<Filter
						dataSource={countries}
						placeholder={inputPlaceholder}
					/>
					{isFiltered && hasFilterResults && (
						<Body size="L" variant="light">
							Results
						</Body>
					)}
					<CountryCardsContainer
						$isCollapsed={shouldExpandFilter}>
						{renderCountries()}
					</CountryCardsContainer>
					{isCollapsed && !isFiltered && <BlurContainer />}
					{!isFiltered && (
						<Button
							variant="filled"
							size="S"
							onClick={toggle}>
							{isCollapsed
								? collapsedLabel
								: expandedLabel}
						</Button>
					)}
				</WrapperCountryCards>
				<LinkContainer>
					<Link href={cardUrl} variant="default" size="L">
						{linkText}
					</Link>
				</LinkContainer>
			</FilterContext.Provider>
		);
	}

	if (previewMode) {
		return (
			<LinkContainer>
				<Link href={cardUrl} variant="default" size="L">
					Apply for Visadefault e-Visa
				</Link>
			</LinkContainer>
		);
	}

	return (
		<>
			<CountryCardSelect
				countries={countries}
				cardUrl={cardUrl}
				placeholder={inputPlaceholder}
			/>
			<LinkContainer>
				<Link href={cardUrl} variant="default" size="L">
					{linkText}
				</Link>
			</LinkContainer>
		</>
	);
};
